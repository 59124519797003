import "../styles/Footer.css";
const Footer = () => {
  return (
    <footer>
      <div className="row">
        <div className="large-8 medium-6 cell">
          <p className="copyright">© {new Date().getFullYear()} FitnessRepay, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;