import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import routes from "./routes";
import Error from './pages/Error';
import Layout from "./components/Layout";
import $ from 'jquery';
import 'foundation-sites';


function App() {

  React.useEffect(() => {
    // Initialize Foundation
    $(document).foundation();
  }, []);
  
  const router = createBrowserRouter([
    {
      // parent route component
      element: <Layout />,
      errorElement: <Error/>,
      // child route components
      children: routes,
    },
  ]);

  return (
      <RouterProvider router={router} />
  );
}

export default App;