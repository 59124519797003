import { Link, useLocation } from 'react-router-dom';
import '../styles/Header.css';
import PathConstants from '../routes/pathConstants';

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <header>
      <div className="header-div">
          <h1 className="title"><Link to={PathConstants.HOME}>Fitness<span className="repay">REPAY</span></Link></h1>
          <nav className="navbar">
              <ul className="nav-list">
                  <li className={pathname === "/repay" ? ("nav-item selected") : ("nav-item") }><Link to={PathConstants.REPAY}>Repay Form</Link></li>
                  <li className={pathname === "/join" ? ("nav-item selected") : ("nav-item") }><Link to={PathConstants.JOIN}>For Gyms</Link></li>
              </ul>
          </nav>
      </div>
    </header>
  )
};
export default Header;
