// ./src/components/Layout.js
import { Outlet } from "react-router-dom";
import "../styles/Layout.css"
import Header from "./Header";
import Footer from "./Footer";
import { Suspense } from "react"
import Loader from "./Loader";

export default function Layout() {
    return (
        <>
            <Header />
            <main>    
                <Suspense fallback={<Loader/>}>            
                    <Outlet />
                </Suspense>
            </main>
            <Footer />
        </>
    )
};