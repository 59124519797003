import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/Error.css";

class Error extends React.Component{
    render(){
      return (
        <section className="error">
          <div className="text-center grid-x">
            <div className="cell">
              <h1>FITNESS<span className="repay">REPAY</span></h1>
              <h2>
                <p style={{textAlign:"center"}}>We had an issue, contact us or try again.</p>
              </h2>
              <p className="go-home">
                <Link to="/">{"Home"}</Link>
              </p>
            </div>
          </div>
        </section>
      );
    }
}
export default Error;