import React from "react"
import PathConstants from "./pathConstants"

const Home = React.lazy(() => import("../pages/Home"));
// other page components...
const Repay = React.lazy(() => import("../pages/Repay"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const Join = React.lazy(() => import("../pages/Join"));
const BecomeFitness = React.lazy(() => import("../pages/gyms/BecomeFitness"));

const routes = [
    {
        path: PathConstants.HOME,
        element: <Home />,
      },
      // other pages....
      {
        path: PathConstants.REPAY,
        element: <Repay />,
      },
      {
        path: PathConstants.JOIN,
        element: <Join />,
      },
      {
        path: PathConstants.BECOMEFITNESS,
        element: <BecomeFitness />,
      },
      // 404 not found, catch all
      {
        path: '*',
        element: <NotFound />,
      }, 
]

export default routes